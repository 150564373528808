var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('PAGES.EVENTS.LIST.TABLE.FILTER'),"single-line":"","hide-details":""},model:{value:(_vm.textSearch),callback:function ($$v) {_vm.textSearch=$$v},expression:"textSearch"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"fixed-header":"","loading-text":"Loading... Please wait","headers":_vm.tableData.headers,"items":_vm.dataTableList,"loading":_vm.eventLoading,"item-key":"ID","page":1,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"search":_vm.tableData.search},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.date_of_birth",fn:function(ref){
var item = ref.item;
return [(item.date_of_birth != null)?_c('span',[_vm._v(_vm._s(new Date(item.date_of_birth).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric" })))]):_vm._e()]}},{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [(item.start_date != null)?_c('span',[_vm._v(_vm._s(new Date(item.start_date).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }))+" ")]):_c('span',[_vm._v("---")])]}},{key:"item.end_date",fn:function(ref){
var item = ref.item;
return [(item.end_date != null)?_c('span',[_vm._v(_vm._s(new Date(item.end_date).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }))+" ")]):_c('span',[_vm._v("---")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(item.createdAt != null)?_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }))+" ")]):_c('span',[_vm._v("---")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [(item.updatedAt != null)?_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }))+" ")]):_c('span',[_vm._v("---")])]}},{key:"item.event_date_time",fn:function(ref){
var item = ref.item;
return [(item.event_date_time != null)?_c('span',[_vm._v(_vm._s(new Date(item.event_date_time).toLocaleString([], { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" }))+" ")]):_c('span',[_vm._v("---")])]}},{key:"item.subscription_date",fn:function(ref){
var item = ref.item;
return [(item.subscription_date != null)?_c('span',[_vm._v(_vm._s(new Date(item.subscription_date).toLocaleDateString()))]):_vm._e()]}},{key:"item.reservations",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.countReservations(item.reservations))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.status == true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-minus-circle")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"}},[_c('v-icon',{on:{"click":function($event){return _vm.reservationsList(item)}}},[_vm._v("mdi-format-list-checks")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"}},[_c('v-icon',{on:{"click":function($event){return _vm.manageItem(item)}}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"red"}},[_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete-outline")])],1)]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t("COMMON.DIALOG.DELETE_CONFIRM"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t("COMMON.DIALOG.ABORT"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t("COMMON.DIALOG.OK"))+" ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px","persistent":true},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)]},proxy:true}])})],1)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }