<template>
  <div class="row">
    <div class="col-md-12">
      <template>
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="textSearch"
              append-icon="search"
              :label="$t('PAGES.EVENTS.LIST.TABLE.FILTER')"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            fixed-header
            loading-text="Loading... Please wait"
            class="elevation-1"
            :headers="tableData.headers"
            :items="dataTableList"
            :loading="eventLoading"
            item-key="ID"
            :page="1"
            :items-per-page="itemsPerPage"
            :footer-props="footerProps"
            :search="tableData.search"
          >
            <template v-slot:item.code="{ item }">
              {{ item.code }}
            </template>
            <template v-slot:item.date_of_birth="{ item }">
              <span v-if="item.date_of_birth != null">{{
                new Date(item.date_of_birth).toLocaleString([], {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric"
                })
              }}</span>
            </template>
            <template v-slot:item.start_date="{ item }">
              <span v-if="item.start_date != null"
                >{{
                  new Date(item.start_date).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.end_date="{ item }">
              <span v-if="item.end_date != null"
                >{{
                  new Date(item.end_date).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span v-if="item.createdAt != null"
                >{{
                  new Date(item.createdAt).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.updatedAt="{ item }">
              <span v-if="item.updatedAt != null"
                >{{
                  new Date(item.updatedAt).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.event_date_time="{ item }">
              <span v-if="item.event_date_time != null"
                >{{
                  new Date(item.event_date_time).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.subscription_date="{ item }">
              <span v-if="item.subscription_date != null">{{
                new Date(item.subscription_date).toLocaleDateString()
              }}</span>
            </template>
            <template v-slot:item.reservations="{ item }">
              {{ countReservations(item.reservations) }}
            </template>
            <template v-slot:item.status="{ item }">
              <span>
                <v-icon color="green" v-if="item.status == true"
                >mdi-check-circle</v-icon
                >
                <v-icon color="red" v-else>mdi-minus-circle</v-icon>
              </span>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text icon color="blue">
                <v-icon @click="reservationsList(item)"
                  >mdi-format-list-checks</v-icon
                >
              </v-btn>
              <v-btn text icon color="blue">
                <v-icon @click="manageItem(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn text icon color="red">
                <v-icon @click="deleteItem(item)">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline"
                      >{{ $t("COMMON.DIALOG.DELETE_CONFIRM") }}
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >{{ $t("COMMON.DIALOG.ABORT") }}
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >{{ $t("COMMON.DIALOG.OK") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500px" :persistent="true">
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_EVENTS_LIST,
  DELETE_EVENT
} from "@/core/services/store/events.module";
import { mapGetters } from "vuex";

export default {
  name: "ListEventsForm",
  data() {
    return {
      fieldToSearch: ["code", "title", "city.denomination", "description"],
      textSearch: "",
      filter_list: {
        code: { q: "code", t: "_like" },
        name: { q: "citizen.name", t: "_like" },
        surname: { q: "citizen.surname", t: "_like" },
        status: { q: "status", t: "_eq" },
        fiscalCode: { q: "citizen.fiscalCode", t: "_like" },
        event_date_time_from: { q: "event_date_time", t: "_gt" },
        event_date_time_to: { q: "event_date_time", t: "_lt" }
      },
      dialog_edit: false,
      filters: {
        query: [],
        filters: {},
        sort: [
          {
            direction: "DESC",
            field: "id"
          }
        ],
        page: 1,
        limit: 10
      },
      single_item_loading: false,
      dialog: false,
      dialogDelete: false,
      page: 0,
      totalItems: 0,
      numberOfPages: 1,
      options: {},
      itemsPerPage: 10,
      optionsLength: 6,
      pagination: {
        page: 1,
        rowsPerPage: 20,
        itemsPerPage: 20
      },
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [2, 10, 20, 50, 100],
        "items-per-page-text": this.$t("COMMON.TABLES.PAGINATION")
      },

      // users_list_data: [],
      tableFilters: {},
      tableData: {
        search: "",
        headers: [
          {
            text: this.$t("COMMON.TABLES.COLUMN.CODE"),
            align: "left",
            sortable: true,
            value: "code"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.TITLE"),
            value: "title"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.CITY"),
            value: "city.denomination"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.START_DATE"),
            value: "start_date"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.END_DATE"),
            value: "end_date"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.TIME_SLOT"),
            value: "time_slot"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.RESERVATIONS_PER_TIME_SLOT"),
            value: "reservations_per_time_slot"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.RESERVATIONS"),
            value: "reservations"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.CREATED_AT"),
            value: "createdAt"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.UPDATED_AT"),
            value: "updatedAt"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.STATUS"),
            value: "status"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.OPERATION"),
            value: "action",
            sortable: false
          }
        ]
      },
      editedItem: {},
      defaultItem: {},
      item_to_edit: {},
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      },
      status: [
        {
          value: 1,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_1")
        },
        {
          value: 2,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_2")
        },
        {
          value: 3,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_3")
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["eventsList", "fullStateList", "eventLoading"]),
    dataTableList() {
      return this.eventsList.data;
    },
    totalPages() {
      return this.eventsList.meta != undefined
        ? this.eventsList.meta.pagination.total_pages
        : 0;
    },
    totalResults() {
      return this.eventsList.meta != undefined
        ? this.eventsList.meta.pagination.total
        : 0;
    },
    event_date_time_from_formatted() {
      return this.tableFilters.event_date_time_from
        ? moment(String(this.tableFilters.event_date_time_from)).format(
            "DD/MM/YYYY"
          )
        : "";
    },
    event_date_time_to_formatted() {
      return this.tableFilters.event_date_time_to
        ? moment(String(this.tableFilters.event_date_time_to)).format(
            "DD/MM/YYYY"
          )
        : "";
    }
  },
  components: {},
  methods: {
    countReservations(reservations) {
      let countReservations = 0;
      reservations.map(function(value) {
        if(value.status ==1){
          countReservations++
        }
      });
      return countReservations;
    },
    createFilterPerTable() {
      var filter = {};
      var cmb = "";
      var self = this;
      if (this.textSearch != "") {
        this.fieldToSearch.forEach(function (v) {
          var key1 = "_" + v.replace(".", "_");
          cmb += key1+",";
          filter[key1] = {};
          filter[key1][v] = {};
          filter[key1][v]["_like"] = "%" + self.textSearch + "%";
        });
        filter._cmb_or = cmb.slice(0, -1)
        return filter;
      }
      return {};
    },
    convertDataToPicker(date) {
      if (date != undefined) {
        return new Date(date).getDate();
      }
    },
    onClearDateClicked(type) {
      type == "from"
        ? (this.tableFilters.event_date_time_from = "")
        : (this.tableFilters.event_date_time_to = "");
    },
    readDataFromAPI(reset) {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let pageNumber;
      if (reset == true) {
        pageNumber = 1;
        this.filters.limit = itemsPerPage;
      } else {
        pageNumber = page == 0 ? 1 : page;
        this.filters.limit = itemsPerPage;
      }
      this.filters.page = pageNumber;
      if (sortBy != undefined && sortBy.length > 0) {
        this.filters.sort = [
          {
            direction: sortDesc != undefined && sortDesc[0] ? "DESC" : "ASC",
            field: sortBy[0]
          }
        ];
      }
      this.filters.filters = this.createFilterPerTable();
      if(!this.eventLoading) {
        this.$store.dispatch(FETCH_EVENTS_LIST, this.filters);
      }
    },
    updateDialogLoading(status) {
      this.single_item_loading = status;
    },
    manageItem(item) {
      this.$router.push("/events/edit/" + item.id);
    },
    reservationsList(item) {
      this.$router.push("/events/reservations/" + item.id);
    },
    deleteItem(item) {
      this.editedIndex = this.dataTableList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.dataTableList.splice(this.editedIndex, 1);
      this.$store.dispatch(DELETE_EVENT, this.editedItem);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PAGES.EVENTS.AREA") },
      { title: this.$t("PAGES.EVENTS.LIST.TITLE") }
    ]);
    this.$store.dispatch(FETCH_EVENTS_LIST, this.filters);
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog_edit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.readDataFromAPI();
      }
    },
    textSearch: {
      handler() {
        this.readDataFromAPI();
      }
    }
  }
};
</script>
